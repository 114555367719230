export const elementMixin = {
  methods: {
    /**
     * Focus on element by id
     * @returns
     */
    focusOnElementById(id) {
      if (id) {
        document.getElementById(id).focus();
      }
    },
  },
};
