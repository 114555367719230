<script>
import { AUTH_LOGOUT } from "../store/actions/auth";
//import LanguagePicker from '@/Components/LanguagePicker.vue';
import LanguagePicker from "./LanguagePicker.vue";
// import Subscribe from "./Subscribe.vue";
import { localizationMixin } from "../mixins/localizationMixin";
import { mapGetters } from "vuex";
import { CONFIG } from "../config";
import Button from "primevue/button";
import { elementMixin } from "../mixins/elementMixin";
import { eventBus } from "../main";

export default {
  components: { LanguagePicker, Button },
  mixins: [localizationMixin, elementMixin],
  name: "Header",
  data: () => ({
    isOpen: false,
    idToFocus: "subscribeBtn",
  }),
  computed: {
    ...mapGetters(["getClientConfig", "selectedLanguage"]),

    subscribeUrl() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/subscribe/subscribe_for_updates.html?"
      );
    },
    
  },
  methods: {
    print: function (e) {
      e.preventDefault();
      window.print();
    },
    signout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/");
      });

      this.loading = true;
    },
    loadSubscribeModal() {
      eventBus.$emit("subscribeButtonClick");
      this.isOpen = true;
      this.$nextTick(() => {
        this.focusOnElementById("subscribeCloseBtn");
      });
    },
    closeModal() {
      this.isOpen = !this.isOpen;
      this.focusOnElementById(this.idToFocus);
    },
    skipToMainContent() {
      var mainContent = document.getElementById("main-content");
      mainContent.focus();
    },
  },
};
</script>

<template>
  <div class="main_wrapper flex flex-wrap">
    <a
      class="main-content-skip"
      tabindex="0"
      @keypress="
        e => {
          e.preventDefault();
          if (e.key === 'Enter') {
            skipToMainContent();
          }
        }
      "
      :aria-label="$t('Header.Skiptomain')"
      >{{ $t("Header.Skiptomain") }}</a
    >
    <header id="fpBanner" class="CAIHeader" role="banner" :class="getClientConfig.clientname">
      <div class="branding">
        <a
          class="logo markonly"
          href="#/home"
          :aria-label="$t('Header.logolabel', {client: getClientConfig.clientname})"
        >
          <div class="CAIText" aria-hidden="true">
            {{ translate("Header.CAIText") }}
          </div>
        </a>
        <h1 class="systemName">{{ translate("Header.title") }}</h1>
      </div>

      <!-- <div
        style="
          display: flex;
          flex-flow: row nowrap;
          align-items: end;
          flex: 1 0 auto;
          justify-content: right;
        "
      > -->
      <div class="branding_buttons">
        <Button
          :label="$t('Header.subscribe')"
          v-if="getClientConfig.layout === 'full' && this.getClientConfig.clientname == 'texas'"
          class="subscribe"
          size="small"
          @click="loadSubscribeModal()"
          :id="idToFocus"
        />

        <LanguagePicker></LanguagePicker>
        <div class="userbar">
          <a href="#" @click="print" style="margin-left: 1em" :aria-label=" $t('PageButtons.Print')"
            ><span aria-hidden="true" class="icon icon-print"></span>
            <span class="text">{{ translate("PageButtons.Print") }}</span></a
          >
          <a href="#" @click="signout" style="margin-left: 1em" :aria-label="$t('Header.Signout')"
            ><span aria-hidden="true" class="icon icon-logout"></span>
            <span class="text">{{ translate("Header.Signout") }}</span></a
          >
        </div>
      </div>
      <div v-if="isOpen" v-focustrap :class="isOpen ? 'modal-backdrop overlay' : ''">
        <div
          v-if="isOpen"
          class="modal external"
          id="subscribe"
          style="max-width: 768px; margin: 0 auto"
          aria-hidden="false"
          ref="externalModal"
        >
          <div style="max-height: 95vh; height: 100%; padding: 10px">
            <!-- <iframe
              :src="subscribeUrl"
              title="Subscribe to Updates"
              width="100%"
              style="height: 100%"
            ></iframe> -->
            <iframe
              :src="subscribeUrl"
              :title="$t('Header.subscribeforupdates')"
              width="100%"
              style="height: 100%"
              tabIndex="0"
            ></iframe>
          </div>
          <button
            class="icon icon-close"
             :aria-label="$t('popup.Close')"
            type="button"
            id="subscribeCloseBtn"
            @click="closeModal()"
            tabIndex="0"
          ></button>
        </div>
      </div>
    </header>

    <!--</v-col>
    </v-row>-->
  </div>
</template>

<style scoped>
.modal button.icon {
  background: none;
  border: none;
  color: #8f8f8f;
  cursor: pointer;
  font-size: 1.25em;
  padding: 0;
}
#subscribe.modal button.icon-close {
  position: absolute;
  right: 1em;
  top: 1em;
}
.modal {
  border: 1px solid #90b4ce;
  border-radius: 10px;
  bottom: 1em;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 25%);
  left: 1em;
  padding: 1.25em 1.5em 1.5em;
  position: fixed;
  right: 1em;
  top: 1em;
  z-index: 9999;
}
#subscribe.modal.external {
  background: #fff;
}

.subscribe {
  margin: 0 1em 0 0;
  background: #196cb6;
  font-size: 0.875em;
  text-decoration: none;
  border-radius: 4px;
  color: #fff !important;
  display: inline-block;
  padding: 0.5em 1em;
}
.texas_language button {
  padding: 0.5em 1em;
}
.texas_language button .v-btn__content {
  text-transform: capitalize;
}
.v-container,
.col,
.col-12 {
  padding: 0 12px;
}
.main_wrapper {
  padding: 0;
}

/* Header */
#fpBanner {
  padding: 12px 0 0;
}
.loginpage #fpBanner {
  display: flex;
  flex-flow: row nowrap;
  max-width: 900px;
  margin: auto;
  padding: 1.25rem 1.25rem 0;
  position: relative;
  /* align-items: flex-start; */
  align-items: center;
  z-index: 1;
  /* margin-bottom: 0.45rem; */
  top: 1px;
}
.texas .loginpage #fpBanner {
  background: #367aab;
  border-radius: 4px 4px 0 0;
  max-width: 552px;
}

.acpage #fpBanner,
.acrpage #fpBanner {
  border-bottom: 1px solid #b7b7b7;
  display: flex;
  flex-flow: row nowrap;
  max-width: 700px;
  margin: 0 auto 1em;
  width: 90%;
  font-size: 95%;
  padding: 1.25em 0;
  /* position: relative; */
  align-items: center;
  /* z-index: 1; */
}
.hidebranding.acpage #fpBanner,
.hidebranding.acrpage #fpBanner {
  display: none;
}

.CAIHeader {
  align-items: flex-end;
  color: #005e9e;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.CAIHeader .branding {
  display: flex;
  align-items: center;
}
.loginpage #fpBanner .branding {
  background: #fff;
  border: 1px solid #ccc;
  border-bottom: 1px solid #fff;
  border-radius: 10px 10px 0 0;
  padding: 1em 1em 0;
}
.texas .loginpage #fpBanner .branding {
  background: none;
  border: none;
  border-radius: 0;
  color: #fff;
  padding: 0;
}
/* .acpage #fpBanner .branding,
.acrpage #fpBanner .branding {
  background: #fff;
  padding: 0 1em 0 1em;
} */

.CAIHeader .logo {
  background: url("../assets/images/calogo.svg") no-repeat;
  display: inline-block;
  height: 35px;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  width: 115px;
  max-width: 100%;
}
.CAIHeader .logo:hover {
  color: inherit;
  text-decoration: none;
}
.CAIHeader .logo.markonly {
  background: url("../assets/images/calogo.svg") no-repeat;
  padding: 0.5em 0 0 50px;
}
.CAIText {
  font-size: 0.688em; /*11px*/
  font-weight: 400;
  line-height: 10px;
  text-align: center;
}
.CAIHeader a {
  color: #005e9e;
  text-decoration: none;
}
.CAIText span {
  color: #2c9ed9;
}
/* .CAIHeader .systemName {
  border-left: 1px solid #005e9e;
  font-size: 2.125em;
  font-weight: 300;
  margin: 0 0 0 0.5em;
  padding: 0 0 0 0.5em;
} */
/* .texas .CAIHeader .systemName {
  font-weight: 300;
} */
.texas .loginpage .CAIHeader .systemName {
  border-left: 1px solid #fff;
}

.CAIHeader .userbar {
  margin: 0;
  padding: 0;
  font-size: 0.8em;
  line-height: 18px;
  text-align: right;
}

.loginpage .i18n {
  display: flex;
  flex-flow: column nowrap;
  max-width: auto;
  margin: auto;
  width: 97%;
  font-size: 95%;
  padding: 1em;
  position: absolute;
  align-items: flex-end;
  z-index: 1;
}
.acpage .i18n {
  display: flex;
  flex-flow: column nowrap;
  max-width: auto;
  margin: auto;
  width: 97%;
  font-size: 95%;
  padding: 1em;
  position: absolute;
  align-items: flex-end;
  z-index: 1;
}
.acrpage .i18n {
  display: flex;
  flex-flow: column nowrap;
  max-width: auto;
  margin: auto;
  width: 97%;
  font-size: 95%;
  padding: 1em;
  position: absolute;
  align-items: flex-end;
  z-index: 1;
}

.i18n {
  display: flex;
  flex-flow: column nowrap;
  max-width: auto;
  margin: auto;
  width: 77%;
  font-size: 95%;
  padding: 0 10em 0;
  position: absolute;
  align-items: flex-end;
  z-index: 1;
}
.loginpage .CAIHeader .userbar {
  display: none;
}

.acpage .CAIHeader .userbar {
  display: none;
}

.acrpage .CAIHeader .userbar {
  display: none;
}

.arkansas.CAIHeader .CAIText,
.delaware.CAIHeader .CAIText,
.florida.CAIHeader .CAIText,
.hawaii.CAIHeader .CAIText,
.newhampshire.CAIHeader .CAIText,
.texas.CAIHeader .CAIText,
.westvirginia.CAIHeader .CAIText,
.virginislands.CAIHeader .CAIText {
  display: none;
}
.arkansas.CAIHeader .logo {
  background: url("../assets/images/logo-arkansas.svg") no-repeat;
  background-position-y: 50%;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 270px;
}
.arkansas.CAIHeader .systemName {
  margin-left: 0.25rem;
}
.delaware.CAIHeader .logo {
  background: url("../assets/images/logo-delaware.svg") no-repeat;
  background-position-y: 50%;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 320px;
}
.virginislands.CAIHeader .logo {
  background: url("../assets/images/logo-virginislands.svg") no-repeat;
  background-position-y: 50%;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 230px;
}
.florida.CAIHeader .logo {
  background: url("../assets/images/logo-florida.svg") no-repeat;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 179px;
}
.hawaii.CAIHeader .logo {
  background: url("../assets/images/logo-hawaii.svg") no-repeat;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 188px;
}
.newhampshire.CAIHeader .logo {
  background: url("../assets/images/logo-newhampshire.svg") no-repeat;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 224px;
}
.texas .CAIHeader .logo {
  /* background: url("../assets/images/texas_logo.svg") no-repeat; */
  background: url("../assets/images/texas-TA.svg") no-repeat;
  background-size: 100%;
  height: 50px;
  padding: 0;
  width: 350px;
}
.texas .loginpage .CAIHeader .logo {
  background: url("../assets/images/TEA-logo-white.svg") no-repeat;
  width: 102px;
}
.texas .acpage .CAIHeader .logo,
.texas .acrpage .CAIHeader .logo {
  background: url("../assets/images/TEA-logo.svg") no-repeat;
  width: 102px;
}
.westvirginia.CAIHeader .logo {
  background: url("../assets/images/logo-westvirginia.png") no-repeat;
  background-position-y: 50%;
  background-size: contain;
  height: 50px;
  padding: 0;
  width: 198px;
}

.branding_buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1 0 auto;
  justify-content: right;
  margin-left: auto;
}

.main-content-skip {
  margin: 0.5rem 0 0 0;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.main-content-skip:focus {
  width: auto;
  height: auto;
  overflow: visible;
}

/*.branding {
    padding: 1em 1em 0;
    z-index: 1;
}*/

@media screen and (max-width: 767px) {
  #fpBanner,
  .loginpage #fpBanner {
    align-items: stretch;
    flex-flow: column nowrap;
    padding: 1rem 0 0;
    width: 100%;
  }
  .texas .loginpage #fpBanner {
    margin-top: 48px;
    padding: 1.25em 1.25em 0;
  }
  #fpBanner .branding,
  .loginpage #fpBanner .branding {
    width: 100%;
    border: none;
    flex-flow: column;
    align-items: center;
    padding: 0;
  }
  .texas .loginpage #fpBanner .branding {
    flex-flow: row;
  }
  #fpBanner .branding_buttons {
    border: none;
    border-width: 0 1px;
    justify-content: center;
    padding: 0;
    width: 100%;
  }
  .texas .loginpage #fpBanner .branding_buttons {
    border: none;
    padding: 0;
  }
  .texas #app .branding_buttons {
    margin-left: initial;
  }
  .CAIHeader .branding {
    flex: 1 1 100%;
    justify-content: flex-start;
    padding: 0 0 0.5em 0;
  }
  .texas .loginpage .CAIHeader .branding {
    justify-content: left;
    padding: 0;
  }
  .texas .CAIHeader .logo {
    height: 26px;
    width: 200px;
  }
  /* .florida .loginpage .CAIHeader .logo {
    height: 40px;
    width: 150px;
  } */
  .texas .loginpage .CAIHeader .logo {
    /* height: 32px; */
    width: 65px;
  }
  .CAIHeader .systemName {
    font-size: 1.5em;
  }
  .texas .CAIHeader .systemName {
    font-size: 1.25em;
  }
  .texas .loginpage .CAIHeader .systemName {
    border-top: none;
    font-size: 1.5em;
  }
  .loginpage #language_picker,
  .texas .loginpage #language_picker {
    margin-top: 0;
    width: 100%;
  }
  /* .loginpage #language_picker button,
  .texas .loginpage #language_picker button {
    width: 100% !important;
  } */
}
@media screen and (max-height: 800px) {
  .texas .loginpage #fpBanner {
    margin-top: 48px;
  }
}
@media screen and (min-width: 501px) and (max-width: 1024px) {
  /* .loginpage #fpBanner {
    padding-left: 0.8em;
  } */
  .texas .CAIHeader .logo {
    height: 30px;
    width: 250px;
  }
  .texas .loginpage .CAIHeader .logo {
    background-position: center;
    height: 50px;
    width: 102px;
  }
  .texas .CAIHeader .systemName {
    font-size: 1.5em;
  }
  .texas .loginpage .CAIHeader .systemName {
    font-size: 2.125rem;
    font-weight: 300;
    padding: 0 0 0 0.75rem;
  }
}

@media screen and (max-width: 500px) {
  .loginpage #fpBanner {
    margin: 0;
    padding: 0;
  }
  #fpBanner .branding {
    align-items: center;
    flex-flow: column;
    padding: 1rem 0 0;
  }
  .loginpage #fpBanner .branding {
    padding: 1rem 0 0;
  }
  .texas .loginpage #fpBanner .branding {
    flex-flow: row;
  }
  .CAIHeader .systemName {
    border: none;
    border-top: 1px solid #005e9e;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0.5rem 0;
    padding: 0.25rem 0 0;
    text-align: center;
    width: 100%;
  }
  .texas .loginpage .CAIHeader .systemName {
    margin: 0 0 0 0.5rem;
    padding: 0 0 0 0.5rem;
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .CAIHeader .systemName {
    border: none;
    border-top: 1px solid #005e9e;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0.5rem 0;
    padding: 0.25rem 0 0;
    text-align: center;
    width: 100%;
  }
  .texas .loginpage .CAIHeader .systemName {
    margin: 0 0 0 0.5rem;
    padding: 0 0 0 0.5rem;
    text-align: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 899px) {
  :lang(es) .CAIHeader .systemName {
    font-size: 1.5rem;
    padding: 0.5rem 0 0.5rem 0.75rem;
  }
}
@media print {
  .CAIHeader .logo.markonly {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .CAIHeader .userbar {
    display: none;
  }

  .branding_buttons {
    display: none;
  }
}
</style>
