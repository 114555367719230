<script>
import { mapGetters } from "vuex";
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "LoadingOverlay",
  components: {
    ProgressSpinner,
  },

  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>

<template>
  <div v-if="isLoading" id="overlay">
    <ProgressSpinner id="progress-spinner" strokeWidth="5" />
  </div>
</template>

<style scoped>
#overlay {
  bottom: 0;
  left: 0;
  opacity: 0.75;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9997;
  background-color: rgba(77, 73, 73, 0.5); /* Black background with opacity */
}
#progress-spinner {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -50px;
}
</style>
