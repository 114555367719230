<template>
  <div
    v-if="isOpen"
    ref="modalContainer"
    role="dialog"
    aria-hidden="false"
    @keydown="checkKeyEvent"
    class="dialog_container"
  >
    <slot name="modal-content" :iframeloaded="iframeloaded"></slot>
  </div>
</template>

<script>
//import DownloadFileModal from "@/components/DownloadFileModal.vue";
export default {
  name: "FocusModal",
  // components: { DownloadFileModal },
  computed: {
    isOpen: {
      get() {
        return this.modalOpen;
      },
      set() {
        this.close();
      },
    },
  },
  props: ["modalOpen", "modalId"],
  data() {
    return {
      initialFocusEl: null,
    };
  },

  updated() {
    if (this.$refs.modalContainer) {
      this.initialFocusEl = document.activeElement;
      document.activeElement.blur();
      const firstfocus = this.$refs.modalContainer.querySelectorAll(
        'button, [tabindex]:not([tabindex="-1"])'
      );

      firstfocus[0].focus();
    }
  },

  methods: {
    iframeloaded() {
      if (this.$refs.modalContainer) {
        // this.initialFocusEl = document.activeElement;
        document.activeElement.blur();
        const firstfocus = this.$refs.modalContainer.querySelectorAll(
          'button, [tabindex]:not([tabindex="-1"])'
        );
        firstfocus[0].focus();
        var b = document.querySelector(".p-dialog.p-component");

        if (b) {
          b.setAttribute("aria-hidden", "false");
        }

        var dialog = document.querySelector(".p-dialog-mask");
        if (dialog) {
          dialog.classList.add("modal-backdrop");
          dialog.classList.add("overlay");
          if (this.modalId) {
            dialog.id = this.modalId;
          }
        }
      }
    },
    close() {
      // this.modalOpen=false;
      var b = document.querySelector(".p-dialog.p-component");

      if (b) {
        b.setAttribute("aria-hidden", "true");
      }
      this.$emit("close");
      this.initialFocusEl.focus();
    },
    checkKeyEvent(event) {
      // close modal and return early if escape
      if (event.key === "Escape") {
        //this.modalOpen = false;
        this.close();

        return;
      }
      const focusableList = this.$refs.modalContainer.querySelectorAll(
        'button, [href],iframe,input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      // escape early if only 1 or no elements to focus
      if (focusableList.length < 2 && event.key === "Tab") {
        event.preventDefault();
        return;
      }
      const last = focusableList.length - 1;
      if (event.key === "Tab" && event.shiftKey === false && event.target === focusableList[last]) {
        event.preventDefault();
        focusableList[0].focus();
      } else if (
        event.key === "Tab" &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault();
        focusableList[last].focus();
      }
    },
  },
};
</script>

<style lang="scss">
.p-dialog-content.full_modal {
  height: 100%;
}
.dialog_container {
  height: 100%;
}
</style>
