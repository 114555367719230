import CacheNode from "./CacheNode";

class LRUCacheGrowthChart {
  static get SessionStorageKey() {
    return "LRUCache_GrowChart";
  }
  constructor({ maxSize = 3 }) {
    // Use sentinel head and tail nodes so we don't need to deal with edge cases
    this.head = new CacheNode({ key: "head" });
    this.tail = new CacheNode({ key: "tail" });
    this.head.next = this.tail;
    this.tail.prev = this.head;
    this.maxSize = maxSize;
    this.nodeLookup = {};
    this.restoreCache();
  }

  /**
   * If the LRU Cache instance is re-initialized and we have data in session storage
   * (happens on page reload for example), restore the LRU Cache
   * @returns
   */
  restoreCache() {
    let sessionData = sessionStorage.getItem(LRUCacheGrowthChart.SessionStorageKey);
    if (!sessionData) return;
    let cachedData = JSON.parse(sessionData);
    this.maxSize = cachedData.maxSize;
    cachedData.nodes.forEach((key) => {
      let nodeBeforeTail = this.tail.prev;
      let newNode = new CacheNode({
        key: key,
        next: this.tail,
        prev: nodeBeforeTail,
      });
      nodeBeforeTail.next = newNode;
      this.tail.prev = newNode;
      this.nodeLookup[key] = newNode;
    });
  }

  saveSnapshotToSessionStorage() {
    // Since JSON.stringify doesn't work with circular structures, iterate over the cached nodes
    // from left to right (most to least recently used) and store them as an array
    let temp = this.head.next;
    let cachedNodes = [];
    while (temp != this.tail) {
      cachedNodes.push(temp.key);
      temp = temp.next;
    }
    const snapShot = {
      maxSize: this.maxSize,
      nodes: cachedNodes,
    };
    sessionStorage.setItem(LRUCacheGrowthChart.SessionStorageKey, JSON.stringify(snapShot));
  }

  put(key) {
    // If for whatever reason we are putting an existing key again
    // (cache was clear while vuex state wasn't for instance)
    // mark the key as most recently used
    if (key in this.nodeLookup) {
      this.moveMostRecentlyUsedToFront(this.nodeLookup[key]);
      return;
    }

    if (Object.keys(this.nodeLookup).length === this.maxSize) this.removeLeastRecentlyUsed();
    let newNode = new CacheNode({ key: key, next: null, prev: null });
    let currNodeAfterHead = this.head.next;
    this.head.next = newNode;
    newNode.prev = this.head;
    newNode.next = currNodeAfterHead;
    currNodeAfterHead.prev = newNode;
    this.nodeLookup[key] = newNode;
    this.saveSnapshotToSessionStorage();
  }

  get(key) {
    if (!(key in this.nodeLookup)) return -1;
    let retrievedNode = this.nodeLookup[key];
    this.moveMostRecentlyUsedToFront(retrievedNode);
    this.saveSnapshotToSessionStorage();
    return retrievedNode.key;
  }

  moveMostRecentlyUsedToFront(node) {
    if (node.prev == this.head) return;
    // Remove the node from its current position
    let nodeBefore = node.prev;
    let nodeAfter = node.next;
    nodeBefore.next = nodeAfter;
    nodeAfter.prev = nodeBefore;

    // Move the node to the head of the list
    let currNodeAfterHead = this.head.next;
    this.head.next = node;
    node.prev = this.head;
    currNodeAfterHead.prev = node;
    node.next = currNodeAfterHead;
  }

  removeLeastRecentlyUsed() {
    let nodeToRemove = this.tail.prev;
    if (nodeToRemove == this.head) return;
    let newLastNode = nodeToRemove.prev;
    newLastNode.next = this.tail;
    this.tail.prev = newLastNode;

    // Remove the node from the lookup and sessionStorage
    delete this.nodeLookup[nodeToRemove.key];
    sessionStorage.removeItem(nodeToRemove.key);
  }
}

export default LRUCacheGrowthChart;
