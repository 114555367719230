const ALL_CONFIGS = {
  local: {
    BACKEND_URL: "http://localhost:5010/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/uat/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 10,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  dev: {
    BACKEND_URL: "https://677au9ce34.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/dev/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  test: {
    BACKEND_URL: "https://1b2zumetn1.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/test/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  uat: {
    BACKEND_URL: "https://874qagw9m2.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/uat/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  staging: {
    BACKEND_URL: "https://6wd9v2c7ac.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/uat/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  lt: {
    BACKEND_URL: "https://jsm2qwvada.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/test/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  prodlt: {
    BACKEND_URL: "https://j6j5woynkj.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/prod/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
  prod: {
    BACKEND_URL: "https://sl9uykdfh1.execute-api.us-east-1.amazonaws.com/Prod/",
    PORTAL_BASE_URL: "https://files.portal.cambiumast.com/familyportal/prod/",
    // 'BROWSERS_SUPPORT_URL': 'https://fsassessments.org/supported-systems-requirements.html',
    // 'TEXAS_BROWSERS_SUPPORT_URL': 'https://www.texasassessment.gov/tech-guide.html#Setting_Up_Workstations_to_Access_Other_CAI_Systems',
    TERMS_OF_USE_TEXAS: "https://www.texasassessment.gov/terms",
    TEXAS_CANCELLATION_TEST:
      "https://www.texasassessment.gov/-/media/project/client-portals/texas/pdf/cancellation-of-staar-testing.pdf",
    NEW_MSG_CONFIG: 30,
    ENV: window.Environment,
    AUTO_LOGOUT_MINS: 20,
    AUTO_LOGOUT_COUNTDOWN_SECS: 30,
  },
};

export const CONFIG = ALL_CONFIGS[window.Environment];
