export const GET_SCORES_FOR_YEAR = "GET_SCORES_FOR_YEAR";
export const DOWNLOAD_ISR_PDF = "DOWNLOAD_ISR_PDF";
export const GET_ISR_LANGUAGES = "GET_ISR_LANGUAGES";
export const SET_SORTBY_SUBJECT = "SET_SORTBY_SUBJECT";
export const SET_SHOW_ALLTESTS = "SET_SHOW_ALLTESTS";
export const SET_SUBJECT_FILTER = "SET_SUBJECT_FILTER";
export const GET_SCORES_DETAIL_REPORT = "GET_SCORES_DETAIL_REPORT";
export const GET_DETAIL_REPORT_IRIS_RESPONSE_ITEM = "GET_DETAIL_REPORT_IRIS_RESPONSE_ITEM";
export const UPDATE_AVAILABLE_STUDENT_SCORES = "UPDATE_AVAILABLE_STUDENT_SCORES";
export const GET_CUMULATIVE_HISTORY_SCORES = "GET_CUMULATIVE_HISTORY_SCORES";
export const CLEAR_STUDENT_DATA = "CLEAR_STUDENT_DATA";
export const CLEAR_ALL_STUDENT_SCORES = "CLEAR_ALL_STUDENT_SCORES"; // clear allStudentScores object
export const GET_STUDENT_SCORES_FROM_BROWSER_CACHE = "GET_STUDENT_SCORES_FROM_BROWSER_CACHE";
export const REMOVE_ITEM_SCORES_WITH_CONDITION_CODE = "REMOVE_ITEM_SCORES_WITH_CONDITION_CODE";
export const GET_STUDENT_VIDEO_REPORTS = "GET_STUDENT_VIDEO_REPORTS";
export const GET_METAMETRICS_GROWTH_CHART = "GET_METAMETRICS_GROWTH_CHART";
