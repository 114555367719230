/**
 * Return a list of DoB years starting from startYear to the current year
 * @param {number} startYear
 * @returns
 */
export const getDobYears = function(startYear = 1970) {
  return Array(new Date().getFullYear() - startYear + 1)
    .fill(0)
    .map((e, i) => i + startYear)
    .reverse();
};

const monthsAndSeasons = [
  "January",
  "February",
  "Spring",
  "March",
  "April",
  "May",
  "Summer",
  "June",
  "July",
  "August",
  "Fall",
  "September",
  "October",
  "November",
  "December",
];

/**
 * Custom sort function to sort the opps by test reason from most-least recent
 * @returns
 */
export const testWindowSort = (a, b) => {
  let [aMonth, aYear] = a.testReason.split(" ");
  let [bMonth, bYear] = b.testReason.split(" ");
  return bYear - aYear || monthsAndSeasons.indexOf(bMonth) - monthsAndSeasons.indexOf(aMonth);
};
