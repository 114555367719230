import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import vuetify from "./plugins/vuetify";
import formatdate from "./filters/formatdate";
import { CONFIG } from "./config";
import { i18n } from "./i18n";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import mitt from "mitt";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import ToastService from "primevue/toastservice";
import FocusTrap from "primevue/focustrap";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
// All available themes. Only use 1 at a time
// import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
// import "primevue/resources/themes/bootstrap4-light-purple/theme.css"
// import "primevue/resources/themes/bootstrap4-dark-blue/theme.css"
// import "primevue/resources/themes/bootstrap4-dark-purple/theme.css"
// import "primevue/resources/themes/md-light-indigo/theme.css"
// import "primevue/resources/themes/md-light-deeppurple/theme.css"
// import "primevue/resources/themes/md-dark-indigo/theme.css"
// import "primevue/resources/themes/md-dark-deeppurple/theme.css"
// import "primevue/resources/themes/mdc-light-indigo/theme.css";
// import "primevue/resources/themes/mdc-light-deeppurple/theme.css";
// import "primevue/resources/themes/mdc-dark-indigo/theme.css"
// import "primevue/resources/themes/mdc-dark-deeppurple/theme.css";
import "primevue/resources/themes/fluent-light/theme.css";
import "material-icons";
// import "primevue/resources/themes/lara-light-blue/theme.css";
// import "primevue/resources/themes/lara-light-indigo/theme.css"
// import "primevue/resources/themes/lara-light-purple/theme.css"
// import "primevue/resources/themes/lara-light-teal/theme.css";
// import "primevue/resources/themes/lara-dark-blue/theme.css"
// import "primevue/resources/themes/lara-dark-indigo/theme.css"
// import "primevue/resources/themes/lara-dark-purple/theme.css"
// import "primevue/resources/themes/lara-dark-teal/theme.css"
// import "primevue/resources/themes/soho-light/theme.css";
// import "primevue/resources/themes/soho-dark/theme.css"
// import "primevue/resources/themes/viva-light/theme.css";
// import "primevue/resources/themes/viva-dark/theme.css"
// import "primevue/resources/themes/mira/theme.css";

HighchartsMore(Highcharts);
//Set highcharts options so that there is no separator when displaying thousands format
Highcharts.setOptions({
  lang: {
    thousandsSep: "",
  },
});

/*** SVG pattern ***/
var idCounter = 0,
  wrap = Highcharts.wrap,
  each = Highcharts.each;

/**
 * Exposed method to add a pattern to the renderer.
 */
Highcharts.SVGRenderer.prototype.addPattern = function (id, options) {
  var patternElement,
    ren = this;

  if (!id) {
    id = "highcharts-pattern-" + idCounter;
    idCounter += 1;
  }

  patternElement = this.createElement("pattern").attr({
    id: id,
    patternUnits: "userSpaceOnUse",
    width: options.width || 10,
    height: options.height || 10,
  });
  patternElement.add(this.defs);
  patternElement.id = patternElement.element.id;
  patternElement.color = options.color;

  if (options.elements) {
    each(options.elements, function (el) {
      var element = ren.createElement(el.type);
      for (var key in el) {
        if (key === "type") continue;
        element.attr(key, el[key]);
      }
      element.add(patternElement);
    });
  }

  return patternElement;
};

// Add patterns to the defs element
wrap(Highcharts.Chart.prototype, "getContainer", function (proceed) {
  //If VML element then just return
  if (Highcharts.VMLElement) return;

  proceed.apply(this);

  var chart = this,
    renderer = chart.renderer,
    options = chart.options,
    patterns = options.defs && options.defs.patterns;

  // Add user defined patterns
  if (patterns) {
    each(patterns, function (pattern) {
      renderer.addPattern(pattern.id, pattern);
    });
  }
});

// Vue.config.productionTip = false;
const emitter = mitt();
export const eventBus = {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
}; // creating an event bus.

const vueApp = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  // .use(vuetify)
  .use(PrimeVue, {
    zIndex: {
      modal: 1100, //dialog, sidebar
      overlay: 9997, //dropdown, overlaypanel
      menu: 1000, //overlay menus
      tooltip: 1100, //tooltip
    },
  })
  .use(HighchartsVue, { tagName: "charts" })
  .use(ToastService);
vueApp.config.globalProperties.$filters = {
  formatdate: formatdate,
};

vueApp.component("Button", Button);
vueApp.directive("focustrap", FocusTrap);
vueApp.mount("#app");

// Google Analytics Tracking
if (CONFIG.ENV.toLowerCase() == "prod") {
  router.afterEach(to => {
    window.gtag("config", "G-QVML7GZR78", { page_path: to.path.toLowerCase() });
  });
}
