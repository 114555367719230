export const localizationMixin = {
  data: function () {
    return {
      fallbackDict: {
        // Login
        "Login.access_code": "Access Code:",
        "Login.access_code_aria_label": "6-Character Unique Code",
        "Login.dobLabel": "Date of Birth:",
        "Login.dob_day_aria_label": "Day",
        "Login.dob_month_aria_label": "Month",
        "Login.dob_year_aria_label": "Year",
        "Login.error.access_code": "Access Code is required",
        "Login.error.access_code_length": "Access code must be at least 6 characters",
        "Login.error.day": "Day is required",
        "Login.error.fname": "First Name is required",
        "Login.error.month": "Month is required",
        "Login.error.year": "Year is required",
        "Login.firstname_aria_label": "First Name",
        "Login.info": "Enter your child's information",
        "Login.loginFN_label": "First Name:",
        "Login.note": "All fields are required.",
        "Login.Signin": "Sign In",
        "Login.Signon": "Sign On",
        "Login.termsofUse": "By signing in you accept and agree to the",
        "Login.Tx_access_code": "Unique Student Access Code",
        "Login.Tx_access_code_trans": "Código de acceso único del estudiante",
        "Login.Tx_loginFN_label": "Legal First Name: ",
        // AccessCode
        "AccessCode.dob_day_aria_label": "Day",
        "AccessCode.dob_month_aria_label": "Month",
        "AccessCode.dob_year_aria_label": "Year",
        "AccessCode.error.tsdsId": "TSDS Id is required",
        "AccessCode.firstname_aria_label": "Legal First Name",
        "AccessCode.info": "Find Your Access Code",
        "AccessCode.loginFN_label": "Legal First Name:",
        "AccessCode.note": "Enter your child's Information",
        "AccessCode.Search": "Search",
        "AccessCode.tsds": "What is TSDS ID?",
        "AccessCode.tsdsDesc":
          "TSDS IDs are randomly generated unique numbers that provide accurate education data reporting. PEIMS IDs are unique numbers that identify individuals who are reported to TEA through the Public Education Information Management System (PEIMS) and are often the same as local school ID numbers. Contact your local district administration office to find your child's TSDS ID or PEIMS ID.",
        "AccessCode.tsdsLabel": "TSDS ID or PEIMS ID",
        // AccessCodeResult
        "AccessCodeResult.info": "Please save this for your records.",
        "AccessCodeResult.note": "Here is your access code:",
        "AccessCodeResult.searchagain": "Search Again",
        "AccessCodeResult.submit": "Go to Login",
        // App
        "App.cancellationInfo": "Information about Cancellation of Spring 2020 STAAR",
        "App.copy": "Copyright ©",
        "App.NOT_SCORED": "NOT SCORED",
        "App.NOT_SCORED_PREVIOUSLY_ACHIEVED": "NOT SCORED PREVIOUSLY ACHIEVED",
        "App.NOT_TESTED_-_ABSENT": "NOT TESTED—ABSENT",
        "App.text": "All rights reserved. ",
        "App.TOU": "Terms of Use",
        "App.TOUP": "Terms of use & privacy",
        "App.write": "Cambium Assessment, Inc.",
        // Header
        "Header.CAIText": " Cambium Assessment ",
        "Header.Signout": " Sign Out ",
        "Header.subscribe": "Subscribe",
        "Header.title": "Family Portal",
        // PageButtons
        "PageButtons.Glossary": " Glossary ",
        "PageButtons.Guide": " Guide ",
        "PageButtons.Home": " Home ",
        "PageButtons.Print": " Print ",
        "PageButtons.Resources": " Resources ",
        // Login links
        "LoginLinks.accesscode": "Where can I find my access code?",
        "LoginLinks.accesscodehelp": "How do I get my access code?",
        "LoginLinks.accesscodelink": "How do I look up my access code?",
        "LoginLinks.contact": "Contact your school",
        "LoginLinks.Info": "Information and Support",
        "LoginLinks.loginhelp": "Having trouble logging in?",
        "LoginLinks.morehelp": "Need more help?",
        "LoginLinks.moreInfo": "More Login Information",
        "LoginLinks.moresupport": "More Support",
        "LoginLinks.releasedates": "Test Score Release Dates",
        "LoginLinks.supportedBrowsers": "Supported Browsers",
        "LoginLinks.terms": "Terms of Use & Privacy",
      },
    };
  },
  methods: {
    /**
     * Return the English translation of the requested text when the get translation call is still pending
     * This is to prevent the UI from showing the translation keys when the page first loads
     * @param {*} key
     * @param {*} fallbackText
     * @returns
     */
    translate(key, fallbackText = "") {
      const translationResult = this.$t(key); // i18n will return the translation key if no translation exists
      if (translationResult in this.fallbackDict) return this.fallbackDict[translationResult];
      return fallbackText || translationResult;
    },
  },
};