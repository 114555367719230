<template>
  <!-- <select v-model="$i18n.locale">
    <option
      v-for="(key,value) in languages"
      :key="`locale-${key}`"
      :value="value"
    >{{ key +' - '+ value }}</option>
  </select> 
  <v-icon small color="#005e9e">mdi-earth</v-icon> -->

  <div id="language_picker">
    <div class="texas_language" v-if="Object.keys(languages).length == 2">
      <Button
        severity="warning"
        size="small"
        color="#C04C2C"
        style="
          color: white;
          height: 35px;
          font-size: 0.875em;
          font-weight: normal;
          box-shadow: none;
          letter-spacing: normal;
        "
        :lang="getLangKey"
        class="texas_language_button"
        @click="locale = Object.keys(languages).filter((x) => x != locale)[0]"
      >
        {{ getToggleName }}
      </Button>
    </div>
    <Dropdown
      v-if="Object.keys(languages).length > 2"
      v-model="locale"
      :options="localeOptionsForDropdown"
      optionLabel="text"
      optionValue="value"
      @update:model-value="onLanguageDropdownChange"
    >
    </Dropdown>
    <!-- <v-radio-group
      v-if="Object.keys(languages).length > 2"
      v-model="locale"
      @update:modelValue="show = false"
      style="margin: 0; padding: 0"
    >
      <template v-slot:label style="margin: 0 !important">
        <v-icon size="small" color="#005e9e" style="margin-right: 3px" @click="show = true">
          mdi-earth
        </v-icon>

        <a style="text-decoration: underline; font-size: 0.8em" @click="show = true">
          {{ languages[locale] + " - " + locale }}
        </a>
      </template>

      <v-card variant="outlined" rounded v-show="show" elevation="8">
        <v-radio
          v-for="(key, value) in languages"
          :key="`locale-${key}`"
          :model-value="value"
          @click="
            () => {
              show = false;
              locale = value;
            }
          "
        >
          <template v-slot:label>
            <span class="text-primary" @click="show = false" style="font-size: 0.8em">
              {{ key + " - " + value }}
            </span>
          </template>
        </v-radio>
      </v-card>
    </v-radio-group> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "../i18n";
import { i18n } from "../i18n";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
//import { router } from '../router';

export default {
  components: {
    Button,
    Dropdown,
  },
  computed: {
    ...mapGetters([
      "languages",
      "getClientConfig",
      "selectedSchoolYear",
      "authStatus",
      "selectedLanguage",
      "studentScores",
      "allStudentScores",
    ]),
    getToggleName() {
      return this.languages[Object.keys(this.languages).filter((x) => x != this.locale)[0]];
    },
    getLangKey() {
      return Object.keys(this.languages).filter((x) => x != this.locale)[0];
    },
    localeOptionsForDropdown() {
      let options = [];
      Object.keys(this.languages).forEach((key) => {
        options.push({
          text: this.languages[key],
          value: key,
        });
      });
      return options;
    },
    schoolYear() {
      return this.$route.params.year;
    },

    studentOpportunity() {
      let oppKey = this.$route.params.oppkey;
      // If we have the school year, find the opp in the dictionary of all student scores by year
      if (this.schoolYear && this.allStudentScores[this.schoolYear]) {
        return this.allStudentScores[this.schoolYear].opps.find((opp) => opp.oppKey === oppKey);
      }
      // If not, find the opp in the list of current student scores (this list is controlled by the school year selection dropdown in the Home view)
      return this.studentScores.find((opp) => opp.oppKey === oppKey);
    },
  },

  methods: {
    ...mapActions([
      "SET_LANGUAGELIST_FOR_CLIENT",
      "GET_SCORES_FOR_YEAR",
      "GET_SCORES_DETAIL_REPORT",
      "SET_SELECTED_LANGUAGE",
      "GET_NOTIFICATIONS",
    ]),
    async loadNotificaitons() {
      const clientName = this.getClientConfig.clientname;
      const lang = this.selectedLanguage;
      await this.GET_NOTIFICATIONS({ clientName, lang });
    },
  },

  watch: {
    getClientConfig: {
      immediate: true,
      handler: async function (newVal) {
        if (newVal && newVal.clientname) {
          await this.SET_LANGUAGELIST_FOR_CLIENT(newVal.clientname);

          // Need to call loadLanguageAsync here because it looks like in Vue Router 4, guard's code is executed after
          // the external component (which in this case is LanguagePicker) is loaded. Might need to come back to
          // figure out why it's really the case
          await loadLanguageAsync(this.locale, newVal.clientname);
        }
      },
    },

    selectedLanguage: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.locale = this.selectedLanguage;
        }
      },
    },

    locale: {
      immediate: true,
      handler: async function (newVal) {
        if (newVal) {
          await this.SET_SELECTED_LANGUAGE(newVal);
          if (this.getClientConfig.clientname) {
            await loadLanguageAsync(newVal, this.getClientConfig.clientname);
            await this.loadNotificaitons();
          }

          this.locale = newVal;

          if (this.selectedSchoolYear != 0) {
            await this.GET_SCORES_FOR_YEAR({
              year: this.selectedSchoolYear,
            });

            // If we are in the opp details page, fetch detailed report data in the new language
            if (this.$route.path.toLowerCase().startsWith("/details")) {
              var data = {
                oppkey: this.$route.params.oppkey,
                schoolYear: this.selectedSchoolYear,
                showOverallComparisonScores: this.studentOpportunity.showOverallComparisonScores,
                showTestQuestionAggregates: this.studentOpportunity.showTestQuestionAggregates,
                crsAggregateOption: this.studentOpportunity.crsAggregateOption,
                testKey: this.studentOpportunity.testKey,
                windowId: this.studentOpportunity.windowId,
                subject: this.studentOpportunity.subject,
                mode: this.studentOpportunity.mode,
                isPreview: this.studentOpportunity.isPreview,
                waveStatus: this.studentOpportunity.waveStatus,
              };
              await this.GET_SCORES_DETAIL_REPORT(data);
            }
          }
        }
      },
    },
  },

  data() {
    const { locale } = i18n;
    //  const {availableLocales}=i18n;

    var show = false;
    // var availableLocales=['en','es']

    return {
      locale,
      show,
      // availableLocales
    };
  },
};
</script>

<style scoped>
select {
  font-size: 18px;
  /*width: 90px;*/
}
.v-input__control {
  background-color: red !important;
}
/* .texas_language,
.Texas .userbar .subscribe {
  background: #c04c2c;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  color: #fff;
} */
.v-btn__content {
  color: #fff;
}
.texas_language_button {
  padding: 0.5em 1em;
  text-transform: inherit;
  background-color: rgb(192, 76, 44) !important;
  border-color: rgb(192, 76, 44) !important;
  border-radius: 4px;
}
.texas_language_button:hover {
  background: #ce684c !important;
  border: 1px solid #ce684c !important;
}
.texas_language {
  display: flex;
  justify-content: right;
}
@media (max-width: 767px) {
  .loginpage #language_picker button,
  .texas .loginpage #language_picker button {
    display: block;
    margin: 0 0 0.75rem;
  }
  .texas_language {
    justify-content: center;
  }
  .texas .texas_language {
    justify-content: right;
  }
}
@media (max-width: 500px) {
  .loginpage #language_picker button,
  .texas .loginpage #language_picker button {
    display: block;
    margin: 0 0 0.75rem;
    width: 100% !important;
  }
}
</style>
