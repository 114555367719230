import { createStore } from 'vuex'
import auth from './modules/auth'
import common from './modules/common'
import studentdata from './modules/studentdata'

export default createStore({
  state() {
    return {};
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    common,
    studentdata
  }
})
