import { createI18n } from "vue-i18n";
import axios from "axios";
import { CONFIG } from "../config";
import { SHOW_LOADER, HIDE_LOADER } from "../store/actions/common";
import store from "../store";
const DEFAULT_LOCALE = "en";

export function getDefaultLocale() {
  const persistedLocale = localStorage.getItem("locale");

  if (persistedLocale) {
    return persistedLocale;
  }
  /*   const availableLocales = Reflect.ownKeys(messages);
       const navigatorLocale = navigator.language;
       console.log('navigatorLocale', navigatorLocale)
       if (availableLocales.includes(navigatorLocale)) {
           return navigatorLocale;
       }
       const navigatorLang = extractLanguage(navigatorLocale);
   
       if (navigatorLocale.split("-").length > 1) {
           if (availableLocales.includes(navigatorLang)) {
               return navigatorLang;
           }
       }
       const fallback = availableLocales.find(locale => extractLanguage(locale) === navigatorLang);
   
       return fallback ? fallback : DEFAULT_LOCALE; */
  return DEFAULT_LOCALE;
}

export const i18n = createI18n({
  locale: getDefaultLocale(),
  globalInjection: true,
  fallbackLocale: "en",
  silentTranslationWarn: true,
  allowComposition: true,
  warnHtmlInMessage: "off",
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.global.locale = lang;
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang, clientName) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.global.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  await store.dispatch(SHOW_LOADER);
  return axios({
    url: CONFIG.BACKEND_URL + `api/I18n/${lang}/messages`,
    data: Object.assign({}, { clientName: clientName, Languagekey: lang }),
    method: "POST",
    // headers: {"Authorization" : `Bearer ${token}`}
  }).then((response) => {
    let msgs = response.data;
    loadedLanguages.push(lang);
    i18n.global.setLocaleMessage(lang, msgs);
    store.dispatch(HIDE_LOADER);
    setI18nLanguage(lang);
  });
}

/*
 * THIS PAGE NEEDS SOME REFACTORING.
 *  - REMOVE AXIOS FROM HERE
 *  - USE VUEX TO UPDATE THE SELECTED LANGUAGE STORED IN VUEX STATE
 */
