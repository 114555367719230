<script>
import { mapGetters } from "vuex";
import Header from "@/components/Header.vue";
import Snackbar from "@/components/Snackbar.vue";
import AutoLogout from "@/components/AutoLogout.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { CONFIG } from "@/config";
import { localizationMixin } from "./mixins/localizationMixin";
import { eventBus } from "./main";

export default {
  name: "App",
  mixins: [localizationMixin],

  components: {
    Header,
    Snackbar,
    AutoLogout,
    LoadingOverlay,
  },

  data: () => ({ accessCode: "" }),

  computed: {
    ...mapGetters(["isLoading", "getClientConfig", "selectedLanguage", "isAuthenticated"]),

    appclass() {
      var classname = "";
      if (window.frameElement && window.frameElement.nodeName.toLowerCase() == "iframe") {
        classname = "hidebranding ";
      }
      if (this.$route.path.toLowerCase() == "/") {
        classname += "loginpage";
      } else if (this.$route.path.toLowerCase() == "/home") {
        classname += "homepage";
      } else if (this.$route.path.toLowerCase() == "/accesscode") {
        classname += "acpage";
      } else if (this.$route.path.toLowerCase() == "/accesscoderesult") {
        classname += "acrpage";
      }
      return classname;
    },

    termsofUse() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/Family+Portal+Terms+of+Use.pdf"
      );
    },

    termsofUseTexas() {
      if (this.selectedLanguage == "es") {
        return CONFIG.TERMS_OF_USE_TEXAS + "-es.html";
      } else {
        return CONFIG.TERMS_OF_USE_TEXAS + ".html";
      }
    },

    cancellation() {
      return CONFIG.TEXAS_CANCELLATION_TEST;
    },
  },

  methods: {
    openResource(resource) {
      window.open(resource, "_blank");
    },
  },
  created: function () {
    eventBus.$on("schoolYearClick", data => {
      window.gtag("event", "school_year_click", { selectedSchoolYear: data });
    });
    eventBus.$on("ViewDetailedReportTab", data => {
      window.gtag("event", "ViewDetailedReportTab", { viewDetailedReportTabActiveTab: data });
    });
    eventBus.$on("downloadDetailedReportButtonClick", () => {
      window.gtag("event", "download_detailed_report_button_click", {
        downloadDetailedReportButton: window.client,
      });
    });
    eventBus.$on("glossaryButtonClick", () => {
      window.gtag("event", "glossary_button_click");
    });
    eventBus.$on("guideButtonClick", () => {
      window.gtag("event", "guide_button_click");
    });
    eventBus.$on("resourcesButtonClick", () => {
      window.gtag("event", "resources_button_click");
    });
    eventBus.$on("subscribeButtonClick", () => {
      window.gtag("event", "subscribe_button_click");
    });
  },
};
</script>

<template>
  <div :class="appclass">
    <LoadingOverlay />
    <Snackbar />
    <AutoLogout v-if="isAuthenticated" />
    <Header />

    <div class="main_wrapper" id="main-content" tabindex="-1">
      <router-view v-slot="{ Component }" :key="$route.fullPath">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <div class="v-container footerWrapper main_wrapper">
      <footer>
        {{ translate("App.copy") }} {{ new Date().getFullYear() }}
        <span class="footerCAIText">{{ translate("App.write") }} </span>
        {{ translate("App.text") }} |
        <a
          v-if="getClientConfig.layout === 'full' && getClientConfig.clientname === 'texas'"
          href="#"
          @click="openResource(termsofUseTexas)"
        >
          {{ translate("App.TOUP") }}
          <span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
        </a>

        <a v-else href="#" @click="openResource(termsofUse)">
          {{ translate("App.TOU") }}
          <span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
        </a>
      </footer>
    </div>
  </div>
</template>

<style>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
html {
  height: 100vh;
}
body {
  background: #fff;
  color: #797979;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-variant-ligatures: none;
  height: 100%;
}
button {
  cursor: pointer;
}

#app a {
  color: #005e9e;
}
#app {
  font-family: "Roboto", sans-serif;
  height: 100%;
  padding: 0 1em;
}
#app .p-component,
.p-component {
  font-family: "Roboto", sans-serif !important;
}
.loginpage {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginpage > .main_wrapper {
  width: 100%;
}
.loginpage .v-application__wrap {
  justify-content: center;
}
.hidebranding.acpage .v-application__wrap {
  justify-content: center;
}

.acrpage .v-application__wrap {
  justify-content: center;
}
.texas .loginpage.v-application .error--text,
.texas .loginpage .v-alert .v-btn--outlined .v-btn__content .v-icon,
.texas .loginpage .v-alert .v-btn--round .v-btn__content .v-icon {
  color: #fff !important;
  caret-color: #fff !important;
}

.v-main__wrap {
  display: flex;
}

.main_wrapper {
  padding: 0;
}

#app footer {
  color: #333;
  font-size: 0.75em;
  text-align: right;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 0.5em 0 20px;
  position: relative;
}
#app footer span {
  margin-left: 0.25em;
  margin-right: 0.25em;
}
#app footer a {
  margin-left: 0.5em;
}
#app .loginpage .v-container footer {
  border-top: none;
  max-width: 900px;
  padding: 0.5rem 20px 20px;
  /* width: 90%; */
}

.texas #app .loginpage .v-container footer {
  font-size: 0.594rem;
  max-width: 552px;
  padding: 0.5rem 0 0;
  width: 100%;
}
.texas #app .loginpage .v-container footer.second_footer {
  padding-bottom: 1em;
}
#app .hidebranding .v-container .CAIHeader {
  display: none;
}
#app .hidebranding .v-container footer {
  display: none;
}

#app .acpage .v-container footer,
#app .acrpage .v-container footer {
  border-top: 1px solid #b7b7b7;
  max-width: 700px;
  padding: 0.5em 0 1.5em;
  width: 90%;
}

#app .homepage .v-container footer {
  border-top: 1px solid #b7b7b7;
}

.footerWrapper {
  padding: 0 0 12px !important;
}
.footerCAIText {
  font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.hide {
  display: none !important;
}
/* --------------------- */
/* Focus Indicator Style */
a:focus,
button:focus,
[tabindex="0"]:focus,
li[role="tab"]:focus,
.v-radio--is-focused .v-input--selection-controls__input,
.v-input--is-focused .v-text-field__slot,
.v-input--is-focused .v-select__slot {
  outline: 2px dashed #005e9c !important;
  outline-offset: 0 !important;
}
.v-input--selection-controls__ripple {
  display: none;
}
.p-inputswitch:focus,
.p-inputswitch-slider:focus,
.p-focus {
  outline: 2px dashed #005e9c !important;
}
/* --------------------- */
.btn_wrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-family: "Roboto", sans-serif;
}
#app main {
  flex: 0 0 auto;
}
main .v-container {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0;
}
.v-container .v-container {
  padding: 0;
}
.v-container .v-container .row--dense > .v-col,
.v-container .v-container .row--dense > [class*="col-"] {
  padding: 0;
}
.v-container .v-container .flex.md6 {
  flex: 0 0 calc(50% - 1em);
  margin: 0 1em 1em 0;
}
.v-container .v-container .flex.md6:nth-child(even) {
  margin: 0 0 1em 1em;
}
.v-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

:root {
  --blue-color-1: #4b7bec;
  --blue-color-2: #a4bcf5;
  --blue-color-3: #ecf1fd;
  --blue-color-4: #fafbfe;
  --chartreuse-color-1: #a3cb38;
  --chartreuse-color-2: #d1e59b;
  --chartreuse-color-3: #f6faea;
  --chartreuse-color-4: #fcfef8;
  --green-color-1: #26de81;
  --green-color-2: #92eec0;
  --green-color-3: #e9fcf2;
  --green-color-4: #f9fefb;
  --magenta-color-1: #a55eea;
  --magenta-color-2: #d2aef4;
  --magenta-color-3: #f6eefd;
  --magenta-color-4: #fbf9fe;
  --orange-color-1: #fd9644;
  --orange-color-2: #fecaa1;
  --orange-color-3: #fff4ec;
  --orange-color-4: #fffcfa;
  --pink-color-1: #cf6a87;
  --pink-color-2: #e7b4c3;
  --pink-color-3: #faf0f3;
  --pink-color-4: #fdf9fa;
  --purple-color-1: #735bcd;
  --purple-color-2: #b8ace6;
  --purple-color-3: #f1eefa;
  --purple-color-4: #fbfafe;
  --salmon-color-1: #ea8685;
  --salmon-color-2: #f4c2c2;
  --salmon-color-3: #fdf3f3;
  --salmon-color-4: #fffbfb;
  --seafoam-color-1: #55e6c1;
  --seafoam-color-2: #a9f2e0;
  --seafoam-color-3: #edfdf9;
  --seafoam-color-4: #f9fffd;
  --teal-color-1: #63cdda;
  --teal-color-2: #b0e6ec;
  --teal-color-3: #effafb;
  --teal-color-4: #fafefe;
  --svg-icon-filled_arrow: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M8 5v14l11-7L8 5z' fill='%23212121'%3E%3C/path%3E%3C/svg%3E");
}

.new {
  background: #fed330;
  border-radius: 5px;
  color: #000;
  font-size: 1.143em;
  font-weight: bold;
  margin-right: 0.75em;
  padding: 0.25em 0.75em;
}

.noScroll {
  overflow: hidden;
}

.subject_icon {
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 45px;
  justify-content: center;
  left: 7px;
  margin: 0;
  position: absolute;
  top: initial;
  width: 45px;
}
.subject_icon img {
  margin: 0;
  max-width: inherit;
}
/* .subject_icon img {
  max-height: 46px;
  max-width: 43px;
} */
/* .cgreen .subject_icon {
  border-color: var(--green-color-2);
} */

#page_header h1 .year_select > .v-input__control > .v-input__slot {
  margin: 0 !important;
}
#page_header h1 .v-select__selections {
  line-height: 1em;
}
#page_header h1 .v-select__selection--comma {
  color: #1b4679;
  margin: 0;
}
#page_header .v-select__selection--comma {
  color: #1b4679;
  margin: 0;
}
#page_header .v-input__slot:before {
  border-color: #1b4679;
}
.page_info:deep(.p-dropdown) {
  border: none !important;
}
@font-face {
  font-family: "family_portal";
  src: url("./assets/styles/fonts/family_portal.eot");
  src: url("./assets/styles/fonts/family_portal.eot") format("embedded-opentype"),
    url("./assets/styles/fonts/family_portal.ttf") format("truetype"),
    url("./assets/styles/fonts/family_portal.woff") format("woff"),
    url("./assets/styles/fonts/family_portal.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change ./assets/styles/./assets/styles/fonts */
  font-family: "family_portal" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-display:before {
  content: "\e90a";
}

.icon-school:before {
  content: "\e90b";
}

.icon-shield:before {
  content: "\e90c";
}

.icon-download_arrow:before {
  content: "\e907";
}

.icon-info:before {
  content: "\e916";
}

.icon-document:before {
  content: "\e908";
}

.icon-close:before {
  content: "\e909";
}

.icon-down_arrow:before {
  content: "\e906";
}

.icon-up_arrow:before {
  content: "\e918";
}

.icon-correct:before {
  content: "\e91a";
}

.icon-correct {
  color: #0d7f40;
}

.icon-incorrect:before {
  content: "\e919";
}

.icon-previous:before {
  content: "\e90d";
}

.icon-next:before {
  content: "\e90e";
}

.icon-logout:before {
  content: "\e900";
}

.icon-map:before {
  content: "\e901";
}

.icon-report:before {
  content: "\e902";
}

.icon-book:before {
  content: "\e903";
}

.icon-home:before {
  content: "\e904";
}

.icon-print:before {
  content: "\e905";
}

.icon-resources-apple:before {
  content: "\e912";
}
.icon-walkthrough:before {
  content: "\e901";
}
.texas .icon-walkthrough:before {
  content: "\e915";
}

.v-dialog.full_modal [role="dialog"] {
  height: calc(100% - 56px);
}
.v-dialog.full_modal {
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 2em);
  position: relative;
}
.v-dialog.full_modal header {
  background-color: #fff !important;
  border-bottom: 1px solid #90b4ce;
  box-shadow: none;
  color: #2b2f31;
  flex: 0;
  height: auto !important;
}
.v-dialog.full_modal header .v-toolbar__content {
  box-sizing: border-box;
  height: 42px !important;
}
.v-dialog.full_modal header .v-toolbar__content .v-toolbar__title {
  color: #2b2f31;
  font-weight: 700;
}
.v-dialog.full_modal iframe {
  flex: 1;
}
.v-dialog.full_modal .full_modal_close {
  color: #2b2f31 !important;
  position: absolute;
  right: 0.5em;
  top: 0.25em;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media screen and (min-width: 1264px) {
  #app .main_wrapper {
    max-width: 1264px;
    margin: auto;
  }
  #app .loginpage .main_wrapper {
    margin: initial;
  }
  .texas #app {
    padding: 0;
  }
}

@media screen and (min-width: 1900px) {
  #app .main_wrapper {
    max-width: 1785px;
  }
}

@media screen and (max-width: 1024px) {
  .v-container {
    max-width: 100% !important;
    padding: 0;
  }
  /* .main_wrapper {
    padding: 0 1em !important;
  } */
}
@media only screen and (max-width: 610px) {
  .texas #app .loginpage .main_wrapper .grid {
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 900px) {
  /* #app {
    font-size: 90%;
  } */
  .v-container .v-container .flex.md6 {
    flex: 0 0 100%;
    margin: 0 0 1em;
  }
  .v-container .v-container .flex.md6:nth-child(even) {
    margin: 0 0 1em;
  }
}
.v-dialog.full_modal {
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  height: calc(100% - 2em);
}

#language_picker .v-input__control {
  width: 125px;
}
#language_picker .v-input__control .v-input__slot {
  margin: 0;
}
#language_picker .v-input__control .v-input__slot legend {
  padding: 0;
  text-align: right;
}
#language_picker .v-input__control .v-input__slot .v-card {
  left: 20px;
  padding: 0.5em;
  position: absolute;
  top: 26px;
  width: 100%;
  z-index: 1;
}
#language_picker .v-input__control .v-input__slot .v-card .v-radio {
  border-top: 1px solid #ccc;
  padding-top: 0.5em;
}
#language_picker .v-input__control .v-input__slot .v-card .v-radio:first-child {
  border-top: none;
  padding-top: 0;
}
#language_picker .v-input__control .v-messages {
  display: none;
}
/* .loginpage #language_picker {
  margin-top: 38px;
} */
.texas .loginpage #language_picker {
  margin-top: 0;
  position: absolute;
  right: 0;
  top: -40px;
}

.hawaii .loginpage #language_picker {
  display: none;
}

.hawaii .CAIHeader #language_picker {
  display: none;
}

.texas .loginpage .subscribe,
.acpage .subscribe,
.acrpage .subscribe {
  display: none;
}

.v-application--is-ltr .v-text-field .v-label {
  transform-origin: 0% -100% !important;
}
/* Header */

.CAIHeader {
  align-items: flex-end;
  color: #005e9e;
  display: flex;
  justify-content: space-between;
}
.CAIHeader .branding {
  display: flex;
  align-items: center;
}
.CAIHeader .logo {
  background: url("./assets/images/CA-Logo_CLR_Horz.svg") no-repeat;
  display: inline-block;
  height: 35px;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  width: 115px;
}
.CAIHeader .logo:hover {
  color: inherit;
  text-decoration: none;
}
.CAIHeader .logo.markonly {
  background: url("./assets/images/CA-Logo_CLR_Mark.svg") no-repeat;
  padding: 0.5em 0 0 50px;
}
.CAIText {
  font-size: 0.688em;
  /*11px*/
  font-weight: 400;
  line-height: 10px;
  text-align: center;
}
.CAIText span {
  color: #2c9ed9;
}
.CAIHeader .systemName {
  border-left: 1px solid #005e9e;
  font-size: 2.125em;
  font-weight: 300;
  margin: 0 0 0 0.5em;
  padding: 0 0 0 0.5em;
}
.CAIHeader .userbar {
  margin: 0;
  padding: 0;
  font-size: 0.875em;
  line-height: 18px;
  text-align: right;
}

/* Footer */

footer {
  /* border-top: 1px solid #b7b7b7; */
  color: #333;
  font-size: 0.75em;
  margin: 0;
  padding: 0.5em 0;
  text-align: right;
}
#login_screen footer,
.subject footer,
.test footer {
  border-top: none;
}
.footerCAIText {
  font-weight: bold;
}

/* Body */

#mainWrapper {
  font-size: 95%;
  margin: 0 auto;
  /* padding: 1.25em;*/
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 1280px) {
  #mainWrapper {
    font-size: 100%;
    width: 1280px;
  }
}

/* Student Bar */

#student_bar {
  background: #005e9c;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  height: 70px;
  justify-content: space-between;
  margin: 0.5em 0;
  padding: 0.65em 1em;
}
#student_bar h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}
#student_info {
  color: #e5e5e5;
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.875em;
}
#student_info .value {
  margin-left: 30px;
}
#student_info .value:first-child {
  margin-left: 0;
}
#student_info .value span {
  color: #fff;
  font-weight: bold;
}

/* Page Header */

#page_header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1em 0;
  position: relative;
}
.subject #page_header {
  margin: 1em 0 0.25em;
}
#page_header .page_info {
  display: flex;
  flex: 1 0 0;
  flex-flow: column nowrap;
  /* margin-right: 1em; */
}
#page_header .page_info nav {
  color: #333;
  margin: 0 0 0.25em;
}
#page_header .page_info nav a {
  text-decoration: underline;
}
.dashboard #page_header .page_info nav {
  display: none;
}
#page_header h1 {
  color: #000;
  display: flex;
  flex-flow: row wrap;
  font-size: 1.875em;
}
#page_header h1 .bold {
  font-weight: bold;
  padding-right: 0.35em;
}
#page_header h1 .btn-group {
  margin-left: 0.35em;
}
#page_header h1 .btn-group button,
#page_header h1 .btn-group .multiselect {
  border: none;
  border-bottom: 1px solid #1b4679;
  border-radius: 0;
  color: #1b4679;
  font-size: 1em;
  max-width: inherit;
  min-width: inherit;
  padding: 0;
}
#page_header h1 .btn-group .multiselect .caret {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid;
  color: #1b4679;
  margin-left: 10px;
  margin-top: 14px;
}
#page_header h1 .multiselect-container.dropdown-menu {
  border-color: #1b4679;
  border-radius: 0;
  top: calc(100% - 2px);
  width: 100%;
}
#page_header h1 .multiselect-container > li > a {
  padding: 0.25em;
}
#page_header h1 .dropdown-menu > li > a {
  font-size: 1.25em;
}
.select-style {
  align-items: center;
  border-bottom: 1px solid #1b4679;
  border-radius: 0;
  display: flex;
  font-size: 1em;
  max-width: inherit;
  min-width: inherit;
  position: relative;
}

/* .select-style::before {
    color: #1B4679;
    font-size: 0.5em;
    position: absolute;
    right: 0;
    top: 0.75em;
    z-index: 1;
} */

.select-style select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  color: #1b4679;
  cursor: pointer;
  font-size: 1em;
  max-width: 100%;
  min-width: 100%;
  padding: 0 0.75em 0 0;
  position: relative;
  z-index: 1;
}
.select-style .icon {
  font-size: 0.5em;
  margin-left: -1em;
}
#page_buttons {
  display: flex;
  flex-flow: row nowrap;
}
#page_buttons button {
  background: #e7f2fa;
  border: 1px solid #8faacb;
  border-right: none;
  color: #000;
  font-size: 0.625em;
  height: 50px;
  width: 56px;
}
#page_buttons button:first-child,
.dashboard #page_buttons #btn_glossary {
  border-radius: 10px 0 0 10px;
}
#page_buttons button:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 1px solid #8faacb;
}
#page_buttons .btn_wrapper {
  flex-flow: column nowrap;
}
#page_buttons .btn_wrapper .icon {
  color: #1b4679;
  font-size: 2.4em;
  padding: 4px;
}
#page_header .note {
  color: #333;
  flex: 1 1 100%;
  font-size: 0.75em;
}
#page_header label {
  color: #333;
  margin-right: 0.5em;
}
#page_header .sorted_text {
  color: #333;
  display: flex;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  margin: 0.4em 0 0;
}
#page_header .dashboard_options {
  display: flex;
  flex: 1 1 100%;
  flex-flow: row nowrap;
  margin: 0.5em 0 0;
}
#page_header .dashboard_options .subject_filter,
#page_header .dashboard_options .all_tests_toggle {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  margin: 0 1em 0 0;
}

.filter_row .mdi-menu-down {
  margin: 0 !important;
}
.v-select.v-input--is-dirty input {
  display: none;
}

.v-select__selection {
  max-width: none;
}
.v-application .green {
  background-color: #00ffffff !important;
}

#global_resources .p-dialog {
  background: #f2f9fc;
  max-height: 55%;
  max-width: 30%;
}
@media screen and (max-width: 500px) {
  #global_resources .p-dialog {
    max-width: 100%;
  }
  .topContainer {
    width: 100% !important;
  }
}
@media screen and (min-width: 501px) and (max-width: 1280px) {
  #global_resources .p-dialog {
    max-width: 50%;
  }
}
@media screen and (max-height: 700px) {
  #global_resources .p-dialog {
    max-height: 100%;
  }
}
@media screen and (min-width: 701px) and (max-height: 800px) {
  #global_resources .p-dialog {
    max-height: 80%;
  }
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #8faacb !important;
  padding: 0.75rem 1rem !important;
}
#global_resources .p-dialog .p-dialog-header {
  border-bottom: none !important;
}
.p-dialog .p-dialog-content {
  padding: 0 !important;
}
#global_resources .p-dialog .p-dialog-content .dialog_container {
  height: 100%;
}
.p-dialog .full_modal_close {
  color: #004a64 !important;
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.25rem !important;
}
@media screen and (max-width: 767px) {
  .p-dialog .p-dialog-header .p-dialog-title {
    flex: 0 1 calc(100% - 25px);
  }
}

.texas .loginpage .v-row.v-row {
  margin-top: -1em;
}
.texas .loginpage .v-row.v-row .v-col {
  padding-left: 0;
  padding-right: 0;
}
.texas #app .loginpage #mainWrapper a {
  color: #fff;
  text-underline-offset: 2px;
}
#app .loginpage a:focus,
#app .loginpage button:focus,
#app .loginpage .p-inputtext:enabled:focus,
#app .loginpage .p-dropdown.p-component.p-inputwrapper.p-focus {
  border-color: inherit;
  border-radius: 2px;
  box-shadow: none;
  outline: 2px dashed #005e9c !important;
  outline-offset: 2px !important;
}
#app .loginpage .p-dropdown.p-component.p-inputwrapper.p-focus > .p-dropdown-label.p-inputtext {
  border-radius: inherit;
  outline: none !important;
}
.texas #app .loginpage .branding a:focus,
.texas #app .loginpage #mainWrapper a:focus,
.texas #app .loginpage #mainWrapper button:focus,
.texas #app .loginpage #mainWrapper .p-inputtext:enabled:focus,
.texas #app .loginpage #mainWrapper .p-dropdown.p-component.p-inputwrapper.p-focus {
  outline: 2px dashed #fff !important;
}
.texas .loginpage .v-text-field input {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.5em;
}
.texas .loginpage .v-select > .v-input__control > .v-input__slot {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.texas .loginpage .v-select > .v-input__control > .v-input__slot input {
  background: none;
  border: none;
  border-radius: 0;
}
.texas .loginpage .v-select__selections {
  padding-left: 0.5em;
}
.texas .loginpage .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0);
}
.texas .loginpage label.v-label.theme--light {
  margin-left: 0.5em;
}
/* .texas .loginpage label.primary--text.v-label--active,
.texas .loginpage label.theme--light.v-label {
  color: #fff !important;
  transform: translateY(-22px) scale(0.75);
} */
.texas .loginpage label.theme--light.primary--text.v-label--active,
.texas .loginpage label.v-label.v-label--active.theme--light {
  color: #fff !important;
  margin-left: 0;
  transform: translateY(-22px) scale(0.75);
  display: none;
}
.texas .v-application.loginpage .primary {
  background-color: #00486e !important;
  border-color: #00486e !important;
  color: #fff;
  margin-top: 0.75em;
}
.texas .loginpage .v-list-item {
  min-height: 20px !important;
}
.texas .loginpage .v-list-item__content {
  padding: 4px 0;
}

.mobile_tabs {
  display: none !important;
}

/* Subject Icons */
.cblue .subject_icon img {
  filter: brightness(0.75) sepia(1) hue-rotate(180deg) saturate(2);
}
.cchartreuse .subject_icon img {
  filter: sepia(39%) saturate(441%) hue-rotate(29deg) brightness(95%) contrast(88%);
}
.cgreen .subject_icon img {
  filter: brightness(0.75) sepia(1) hue-rotate(105deg) saturate(2);
}
.cmagenta .subject_icon img {
  filter: sepia(16%) saturate(1000%) hue-rotate(249deg) brightness(95%);
}
.corange .subject_icon img {
  filter: brightness(0.75) sepia(1) hue-rotate(353deg) saturate(2);
}
.cpink .subject_icon img {
  filter: sepia(32%) saturate(694%) hue-rotate(292deg) brightness(92%) contrast(92%);
}
.cpurple .subject_icon img {
  filter: sepia(60%) saturate(1500%) hue-rotate(193deg) brightness(90%) contrast(83%);
}
.csalmon .subject_icon img {
  filter: sepia(14%) saturate(1538%) hue-rotate(314deg) brightness(105%) contrast(83%);
}
.cseafoam .subject_icon img {
  filter: sepia(40%) saturate(336%) hue-rotate(100deg) brightness(100%) contrast(80%);
}
.cteal .subject_icon img {
  filter: sepia(30%) saturate(705%) hue-rotate(139deg) brightness(100%) contrast(84%);
}
.school_year_header {
  width: 100%;
}
.texas .school_year_header {
  border-top: none !important;
}

.school_year .v-expansion-panel-content__wrap {
  padding: 1em 0 0 !important;
}
.school_year_header .v-expansion-panel-header__icon {
  transform: scale(1.5);
}
.school_year_header .v-expansion-panel-header__icon .v-icon {
  color: #000 !important;
}

#more_notifications p {
  margin: 0 0 1rem;
}

.modal-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9997;
  pointer-events: all !important;
}

/* Item Rationales */
#response_rubric div[data-hrr-element-type="rubricBlock"] {
  border-top: 1px solid #999;
  margin-top: 1rem;
  padding-top: 1rem;
}
#response_rubric div[data-score]::before {
  display: block;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
#response_rubric div[data-score="4"]::before {
  content: "Score: 4";
}
#response_rubric div[data-score="3"]::before {
  content: "Score: 3";
}
#response_rubric div[data-score="2"]::before {
  content: "Score: 2";
}
#response_rubric div[data-score="1"]::before {
  content: "Score: 1";
}
#response_rubric div[data-hrr-element-type="rubricBlock"] strong {
  font-weight: 500;
}
#response_rubric div[data-hrr-element-type="rubricBlock"] ul {
  padding: 0 0 0 1rem;
}
#response_rubric div[data-hrr-element-type="rubricBlock"] ul li {
  margin: 0.5rem 0;
}

/* Writing Dimension Table Content */
.writing_dimension_section .report_table tbody td ul {
  margin: 0.5rem 0 0 0.5rem;
}
.writing_dimension_section .report_table li {
  margin: 0.25rem 0 0 0.25rem;
}

.student-info-bar-dropdown-items {
  min-width: 200px !important;
}

/* Login Form */
#dobMonth_list .p-dropdown-item,
#dobDay_list .p-dropdown-item,
#dobYear_list .p-dropdown-item,
#acl_dobMonth_list .p-dropdown-item,
#acl_dobDay_list .p-dropdown-item,
#acl_dobYear_list .p-dropdown-item {
  padding: 0.25rem 0.5rem !important;
}
.p-dropdown-items-wrapper {
  max-height: 380px !important;
}

@media screen and (max-width: 420px) {
  .loginpage {
    height: initial;
  }
}

@media screen and (max-width: 767px) {
  .loginpage {
    justify-content: inherit;
  }
  #student_bar {
    height: auto;
  }
  #student_info {
    flex-flow: row wrap;
  }
  #student_info .value {
    margin: 0.5rem 1rem 0 0;
  }
  .footerWrapper {
    padding-bottom: 1em !important;
  }
  #app footer {
    flex-flow: row wrap;
    justify-content: center;
    padding: 0.5em 2em 1em !important;
  }
  .texas #app footer {
    padding: 0.5em 2em 0 !important;
  }
  .mobile_tabs {
    display: flex !important;
  }
  [role="tablist"] {
    display: none !important;
  }
  header {
    top: 0 !important;
    width: 100% !important;
  }
  .fullwidth .subject_icon {
    margin-left: 0 !important;
  }
  .mobile_tabs {
    border-right: 1px solid;
    border-left: 1px solid;
    display: block;
  }
  .mobile_tabs.mobile_subjects {
    border: none;
    border-radius: 10px 10px 0 0;
  }

  .selected_subject .subject_icon {
    height: 50px;
    left: 0;
    margin-right: 12px;
    position: relative;
    width: 50px;
    display: inline-flex;
  }
  .filter_row.selected_subject {
    display: flex;
    align-items: center;
  }

  .mobile_tabs .v-text-field__details {
    display: none;
  }
  /* Colors */
  .cblue.mobile_tabs {
    background-color: var(--blue-color-3) !important;
    border-color: var(--blue-color-2);
  }
  .cblue.mobile_tabs.mobile_subjects {
    background-color: var(--blue-color-2) !important;
  }
  .cblue .tabpanel {
    border-color: var(--blue-color-2) !important;
  }
  .cchartreuse.mobile_tabs {
    background-color: var(--chartreuse-color-3) !important;
    border-color: var(--chartreuse-color-2);
  }
  .cchartreuse.mobile_tabs.mobile_subjects {
    background-color: var(--chartreuse-color-2) !important;
  }
  .cchartreuse .tabpanel {
    border-color: var(--chartreuse-color-2) !important;
  }
  .cgreen.mobile_tabs {
    background-color: var(--green-color-3) !important;
    border-color: var(--green-color-2);
  }
  .cgreen.mobile_tabs.mobile_subjects {
    background-color: var(--green-color-2) !important;
  }
  .cgreen .tabpanel {
    border-color: var(--green-color-2) !important;
  }
  .cmagenta.mobile_tabs {
    background-color: var(--magenta-color-3) !important;
    border-color: var(--magenta-color-2);
  }
  .cmagenta.mobile_tabs.mobile_subjects {
    background-color: var(--magenta-color-2) !important;
  }
  .cmagenta .tabpanel {
    border-color: var(--magenta-color-2) !important;
  }
  .corange.mobile_tabs {
    background-color: var(--orange-color-3) !important;
    border-color: var(--orange-color-2);
  }
  .corange.mobile_tabs.mobile_subjects {
    background-color: var(--orange-color-2) !important;
  }
  .corange .tabpanel {
    border-color: var(--orange-color-2) !important;
  }
  .cpink.mobile_tabs {
    background-color: var(--pink-color-3) !important;
    border-color: var(--pink-color-2);
  }
  .cpink.mobile_tabs.mobile_subjects {
    background-color: var(--pink-color-2) !important;
  }
  .cpink .tabpanel {
    border-color: var(--pink-color-2) !important;
  }
  .cpurple.mobile_tabs {
    background-color: var(--purple-color-3) !important;
    border-color: var(--purple-color-2);
  }
  .cpurple.mobile_tabs.mobile_subjects {
    background-color: var(--purple-color-2) !important;
  }
  .cpurple .tabpanel {
    border-color: var(--purple-color-2) !important;
  }
  .csalmon.mobile_tabs {
    background-color: var(--salmon-color-3) !important;
    border-color: var(--salmon-color-2);
  }
  .csalmon.mobile_tabs.mobile_subjects {
    background-color: var(--salmon-color-2) !important;
  }
  .csalmon .tabpanel {
    border-color: var(--salmon-color-2) !important;
  }
  .cseafoam.mobile_tabs {
    background-color: var(--seafoam-color-3) !important;
    border-color: var(--seafoam-color-2);
  }
  .cseafoam.mobile_tabs.mobile_subjects {
    background-color: var(--seafoam-color-2) !important;
  }
  .cseafoam .tabpanel {
    border-color: var(--seafoam-color-2) !important;
  }
  .cteal.mobile_tabs {
    background-color: var(--teal-color-3) !important;
    border-color: var(--teal-color-2);
  }
  .cteal.mobile_tabs.mobile_subjects {
    background-color: var(--teal-color-2) !important;
  }
  .cteal .tabpanel {
    border-color: var(--teal-color-2) !important;
  }
  /* Subject Page */
  .tabPanels {
    margin-top: 0 !important;
  }

  .tabpanel {
    border-radius: 0 0 10px 10px !important;
  }
  .tabpanel header {
    border-radius: 0 !important;
    padding: 0.7em 1em !important;
  }
  .tabpanel header::before {
    display: none;
  }
  /* Content */
  .dflex-cl {
    flex-flow: column nowrap;
  }
  .texas .dflex-cl {
    padding-top: 0;
  }
  .report-con-left {
    display: none;
  }
  #tab_overall .report-con-left {
    display: flex;
    margin: 0 0 1em !important;
    padding: 0 !important;
    width: 100% !important;
  }
  #tab_overall .report-con-left div {
    width: 100% !important;
  }
  #tab_overall .download-con {
    text-align: center;
  }
  #tab_overall .download-con .btn_detailed_report {
    font-size: 1em;
    height: 6.944em;
    width: 8.333em;
  }
  #tab_overall .download-con .btn_detailed_report .btn_text {
    margin-top: 0.25em;
  }
  .detail-right-con {
    border-left: none !important;
    padding: 0 !important;
  }
  .performance_icon,
  .percentile_number {
    margin: 0 auto;
  }
  .percentile_name,
  .level_name {
    text-align: center;
  }
  .resourcemodalclass.v-dialog:not(.v-dialog--fullscreen) {
    max-height: inherit;
    max-width: inherit;
  }
}
@media screen and (max-height: 800px) {
  .loginpage {
    justify-content: inherit;
  }
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .topContainer {
    width: 50% !important;
  }
}
@media print {
  body {
    font-size: 0.8rem;
  }
  /* body {
    -webkit-print-color-adjust: exact;
  } */
  .CAIHeader {
    -webkit-print-color-adjust: exact;
  }
  .filter_container,
  .barrel_chart {
    -webkit-print-color-adjust: exact;
  }
  .test_card .most_recent h3 {
    border-color: #ccc !important;
  }
  .v-container .v-container .flex.md6,
  .v-container .v-container .flex.md6:nth-child(even) {
    flex: 0 0 100%;
    margin: 0 0 1em;
    max-width: 100%;
  }
  /* #app {
    font-size: 80%;
  } */
  .tabpanel .test_card {
    width: 100%;
  }
  .iconMathBadge {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .pl_icon,
  .percentile_number {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .texas .v-container.footerWrapper.main_wrapper {
    /* position: absolute;
    bottom: 0; */
    margin-top: 1em;
  }
}
</style>
