<template>
  <Dialog
    :header="$t(title)"
    style="width: 95vw; height: 95vh"
    :closable="false"
    :visible="checkisOpen"
    :content-class="resourceclass"
    :aria-label="$t(title)"
  >
    <FocusModal :modalOpen="checkisOpen" :modalId="modalId" @close="cancel">
      <template v-slot:modal-content="slotProps">
        <iframe
          ref="ifrm"
          :src="url"
          :title="title"
          height="100%"
          width="100%"
          @load="slotProps.iframeloaded"
        ></iframe>
        <Button
          v-if="title.toLowerCase() === 'guide' || title.toLowerCase() === 'glossary'"
          class="full_modal_resize"
          @click="cancelAndOpenLink"
          icon="pi pi-external-link"
          text
        />
        <Button
          rounded
          @click="cancel"
          class="full_modal_close"
          icon="pi pi-times"
          :aria-label="$t('popup.Close')"
          text
        />
      </template>
    </FocusModal>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import FocusModal from "./FocusModal.vue";
import Button from "primevue/button";
export default {
  name: "IframeModal",
  components: {
    Dialog,
    FocusModal,
    Button,
  },
  props: {
    message: {
      type: String,
      default: () => "",
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    url: {
      type: String,
      default: () => "",
    },
    resourceclass: {
      type: String,
      default: () => "",
    },
    modalId: {
      type: String,
      default: () => null,
    },
  },
  data: () => ({
    // options: {
    //   color: "primary",
    //   width: 290,
    //   zIndex: 200,
    // },
  }),

  computed: {
    checkisOpen: {
      get() {
        return this.isOpen;
      },
      set() {
        this.cancel();
      },
    },
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    cancelAndOpenLink() {
      if (this.title.toLowerCase() === "guide" || this.title.toLowerCase() === "glossary") {
        window.open(this.url, "_blank");
      }
      this.cancel();
    },
  },
};
</script>

<style scoped>
.full_modal_resize {
  color: #2b2f31 !important;
  position: absolute;
  right: 3.5rem;
  top: 0.25rem;
}
</style>
