<script>
import { mapGetters } from "vuex";
import { CONFIG } from "./../config";
import { localizationMixin } from "../mixins/localizationMixin";

export default {
  name: "LoginLinks",
  mixins: [localizationMixin],
  computed: {
    ...mapGetters(["getClientConfig", "selectedLanguage"]),
    accesscodehelp() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/How+do+I+get+my+access+code.pdf"
      );
    },
    loginhelp() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/Having+Trouble+Logging+In.pdf"
      );
    },
    morehelp() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/Need+more+help.pdf"
      );
    },
    supportedBrowsers() {
      return (
        CONFIG.PORTAL_BASE_URL +
        this.getClientConfig.clientname +
        "/" +
        this.selectedLanguage +
        "/supported_browsers/index.html"
      );
    },
  },
  methods: {
    openResource(resource) {
      window.open(resource, "_blank");
    },
  },
};
</script>

<template>
  <div id="login_help">
    <div id="login_more_info">
      <h2>{{ translate("LoginLinks.moreInfo") }}</h2>
      <ul v-if="getClientConfig.clientname === 'texas'">
        <li>
          <a @click="openResource(accesscodehelp)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.accesscode") }}</a
          >
        </li>
        <li>
          <!-- <a @click="openResource(loginhelp)" tabindex="0"
            ><span class="sr-only">PDF Opens in new window</span>
            {{ translate("LoginLinks.accesscodelink", "How do i look up my access code?") }}</a
          > -->
          <a href="#" @click="() => $router.push('/AccessCode')">
            {{ translate("LoginLinks.accesscodelink") }}
          </a>
        </li>
        <li>
          <a @click="openResource(loginhelp)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.loginhelp") }}</a
          >
        </li>
      </ul>
      <ul v-else>
        <li>
          <a @click="openResource(accesscodehelp)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.accesscodehelp") }}</a
          >
        </li>
        <li>
          <a @click="openResource(loginhelp)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.loginhelp") }}</a
          >
        </li>
        <li>
          <a @click="openResource(morehelp)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.morehelp") }}</a
          >
        </li>
      </ul>
    </div>
    <div id="login_more_support">
      <h2>{{ translate("LoginLinks.moresupport") }}</h2>
      <ul>
        <li v-if="getClientConfig.clientname === 'texas'">
          <span class="icon icon-display"></span
          ><a @click="openResource(morehelp)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.contact") }}</a
          >
        </li>

        <li>
          <span class="icon icon-display"></span
          ><a @click="openResource(supportedBrowsers)" tabindex="0"
            ><span class="sr-only">{{$t("LoginLinks.newwindow") }}</span>
            {{ translate("LoginLinks.supportedBrowsers") }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
div {
  text-align: left;
}

#login_help {
  background: #e7f2fa;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;
}
.texas #login_help {
  background: #18649e;
  border: none;
  border-radius: 0 0 4px 4px;
  padding: 1.25rem;
}
#login_help h2 {
  color: #666;
  font-size: 1rem;
  font-weight: bold;
}
.texas #login_help h2 {
  color: #fff;
}
#login_help ul {
  font-size: 0.875rem;
  margin: 0 0 0 1rem;
  padding-left: 0;
}
.texas #login_help ul {
  list-style: none;
  margin: 0;
}
#login_help ul li {
  margin: 0.75rem 0 0;
}
.texas #login_help ul li {
  margin: 0 0 0.75rem;
}
#login_help a {
  text-decoration: underline;
}
#login_help a:hover {
  color: #333;
  cursor: pointer;
}
#login_more_info {
  flex: 1 0 60%;
  padding: 1rem;
}
.texas #login_more_info {
  padding: 0;
}
#login_more_support {
  border-left: 1px solid #8faacb;
  flex: 1 0 40%;
  padding: 1rem 1rem 1rem 1.5rem;
}
.texas #login_more_support {
  border: none;
  padding: 0;
}
#login_more_support ul {
  list-style: none;
  margin: 0;
}
#login_more_support ul li {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
#login_more_support .icon {
  color: #666;
  font-size: 2rem;
  margin-right: 0.5em;
}
.texas #login_more_support .icon {
  display: none;
}
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (max-width: 500px) {
  /* .texas #login_help {
    flex-flow: column nowrap;
  } */
  .texas #login_more_info {
    flex: 1 0 55%;
  }
  #login_more_info {
    padding-left: 0;
  }
  #login_more_support .icon.icon-display {
    display: none;
  }
  #login_more_info,
  #login_more_support {
    flex: 1 0 100%;
    padding: 0;
  }
  #login_more_support {
    border: none;
    border-top: 1px solid #8faacb;
    margin: 1rem 0 0;
    padding: 0.5rem 0 0;
  }
  #login_help ul li {
    margin: 0.5rem 0 0;
  }
}
</style>
