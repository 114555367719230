import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import * as axios from "axios";
import store from "../store";
import { ClientConfig } from "../clientSpecificConfig";
import { AUTH_REQUEST, AUTO_AUTH_REQUEST, AUTH_SUCCESS, AUTH_LOGOUT } from "../store/actions/auth";
import {
  SET_CLIENT_CONFIG,
  SHOW_LOADER,
  HIDE_LOADER,
  APP_ERROR,
  SET_LOGIN_ATTEMPTS,
  SET_SELECTED_LANGUAGE,
  SET_LANGUAGELIST_FOR_CLIENT,
} from "../store/actions/common";
import { GET_SCORES_FOR_YEAR } from "../store/actions/studentdata";
import { loadLanguageAsync } from "../i18n";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch(AUTH_LOGOUT);
      router.push("/");
      store.dispatch(APP_ERROR, error);
    }
    return Promise.reject(error);
  }
);

var getStateFromUrl = function (to) {
  const subdomains = window.location.host.split("-");
  let clientCfg = {};
  let clientAbbr = "cambium";

  //get client abbreviation from the URL
  if (subdomains.length > 1) clientAbbr = subdomains[0].toLowerCase();
  else if (clientAbbr == "cambium") {
    // Code below is to help with local debugging so that we can specify the querystring once and then
    // save the client name in localstorage for the rest of the time.
    // Everytime a new client is specified then that gets stored in localstorage
    if (window.location.hostname.indexOf("localhost") >= 0) {
      if (to.query.client) {
        clientAbbr = ClientConfig[to.query.client.toLowerCase()]
          ? to.query.client.toLowerCase()
          : clientAbbr;
        if (clientAbbr != "cambium") {
          localStorage.setItem("clientAbbr", clientAbbr);
        }
      }
      let clientAbbrFromSession = localStorage.getItem("clientAbbr");
      if (clientAbbrFromSession) clientAbbr = clientAbbrFromSession;
    }
  }

  //If we have configuration tied to the client abbreviation set the clientCfg variable
  if (ClientConfig[clientAbbr]) {
    clientCfg = ClientConfig[clientAbbr];
  }

  console.log("clientname: " + clientCfg.clientname);

  //set the clientconfig
  if (clientCfg.clientname) {
    store.dispatch(SET_CLIENT_CONFIG, clientCfg);
    document.body.classList.add(clientCfg.clientname.toLowerCase());
  }
};

var autologin = async function (to) {
  var error = to.query.error ? to.query.error : "";
  var loginAttempts = store.getters.loginAttempts;

  if (localStorage.getItem("loginAttempts")) {
    loginAttempts = JSON.parse(localStorage.getItem("loginAttempts"));
  }
  var duration = new Date(Date.now()) - new Date(loginAttempts.timestamp);
  var seconds = Math.floor(duration / 1000);

  var waitTimeInSeconds = (loginAttempts.count / 3) * 120;
  // if we are within wait timeout interval and number of invalid attempts greater than any multiple
  // of 3 then we will logout user even if user provide correct credentials as part of autologin

  // Need to get state here so we have clientConfig data to display the login errors
  // This is because SET_LANGUAGELIST_FOR_CLIENT and  SET_SELECTED_LANGUAGE requires clientName
  // Which we are doing below
  getStateFromUrl(to);

  let autoLoginLanguage = to.query.autoLoginLanguage ? to.query.autoLoginLanguage : "";

  // Set auto-login language if needed
  if (autoLoginLanguage) {
    await store.dispatch(SET_LANGUAGELIST_FOR_CLIENT, store.getters.getClientConfig.clientname);
    await store.dispatch(SET_SELECTED_LANGUAGE, autoLoginLanguage);
    await loadLanguageAsync(autoLoginLanguage, store.getters.getClientConfig.clientname);
  }

  if (
    loginAttempts.count >= 3 &&
    loginAttempts.count % 3 == 0 &&
    seconds >= 0 &&
    seconds <= waitTimeInSeconds
  ) {
    await store.dispatch(AUTH_LOGOUT);
    return;
  }

  if (error.length > 0) {
    await store.dispatch(SET_LOGIN_ATTEMPTS);
    await store.dispatch(AUTH_LOGOUT);
    router.push("/");
    await store.dispatch(APP_ERROR, error);
    return;
  }

  var jwtToken = to.query.jwtToken ? to.query.jwtToken : "";
  if (jwtToken.length > 0) {
    router.push("/");
    await store.dispatch(SHOW_LOADER);
    await store.dispatch(AUTO_AUTH_REQUEST, jwtToken).catch((ex) => {
      console.log("login error", ex);
    });
  }
};

var isValidToken = function () {
  let expiration = window.sessionStorage.getItem("jwttokenexpiration");
  if (!expiration) return false;
  expiration = new Date(expiration).getTime();
  var unixTimestamp = new Date().getTime();
  if (expiration !== null && parseInt(expiration) - unixTimestamp < 0) {
    return false;
  } else return true;
};

const SetStateOnLogin = async (to, _, next) => {
  await autologin(to);
  // Proceed to show login if not authenticated
  if (!store.getters.isAuthenticated) {
    getStateFromUrl(to);
    next();
    return;
  }
  // Show home page if authenticated
  next("/home");
};

const ShowLoginifNotAuthenticated = (to, from) => {
  if (!isValidToken()) {
    store.dispatch(AUTH_LOGOUT);
    store.dispatch(APP_ERROR, "login session expired");
    return { name: "Login" };
  }
  if (store.getters.isAuthenticated) return true;
  else if (sessionStorage.getItem("Student_Info") != null) {
    store.dispatch("SET_STUDENT_INFO");
    getStateFromUrl(to, from);
    return true;
  }
  return { name: "Login" };
};

const routes = [
  {
    path: "/",
    name: "Login | Family Portal",
    component: Login,
    beforeEnter: SetStateOnLogin,
  },
  {
    path: "/home",
    name: "Dashboard | Family Portal",
    beforeEnter: ShowLoginifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/tests/:subject",
    name: "Tests",
    beforeEnter: ShowLoginifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Tests.vue"),
  },
  /*{
    path: "/details/:oppkey",
    name: "Details",
    beforeEnter: ShowLoginifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( "../views/Details.vue"),
  },
  */
  {
    path: "/details/:year/:oppkey",
    name: "Details",
    beforeEnter: ShowLoginifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Details.vue"),
  },

  {
    path: "/accesscode",
    name: "AccessCode",
    beforeEnter: SetStateOnLogin,
    //beforeEnter: ShowLoginifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AccessCode.vue"),
  },
  {
    path: "/accesscoderesult",
    name: "AccessCodeResult",
    //beforeEnter: ShowLoginifNotAuthenticated,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AccessCodeResult.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let title = to.name;
  if (to.params.subject != undefined) {
    title = to.params.subject + " | " + title;
  }
  document.title = title;

  next();
});

window.onload = function () {
  // Accept information from parent page
  window.addEventListener("message", function (event) {
    var data = event.data;
    var cmd = data.cmd;
    // Process business logic
    switch (cmd) {
      case "login":
        if (typeof data.data !== "undefined" && typeof data.data.jwtToken !== "undefined") {
          store.dispatch(SHOW_LOADER);

          store.commit(AUTH_REQUEST);
          sessionStorage.setItem("jwttoken", data.data.jwtToken);
          sessionStorage.setItem("jwttokenexpiration", data.data.jwtTokenExpiration);
          if (data.data.schoolYears) {
            data.data.schoolYears.sort();
          }
          sessionStorage.setItem("Student_Info", JSON.stringify(data.data));
          sessionStorage.setItem("PreviewStudent_Info", JSON.stringify(data.data));
          store.commit(AUTH_SUCCESS, data);
          store
            .dispatch(GET_SCORES_FOR_YEAR, {
              year: store.getters.selectedSchoolYear,
            })
            .then(
              () => {
                store.dispatch(HIDE_LOADER);
                router.push("/home").catch(() => {});
              },
              () => {}
            );
        }
        break;

      case "logout":
        store.dispatch(AUTH_LOGOUT).then(() => {
          router.push("/").catch((ex) => {
            console.log("logout error", ex);
          });
        });
        break;
    }
  });
};

export default router;
