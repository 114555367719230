<script>
import { mapActions } from "vuex";
import { CONFIG } from "../config";
import Button from "primevue/button";
import Dialog from "primevue/dialog";

export default {
  name: "AutoLogout",
  components: {
    Button,
    Dialog,
  },
  data: () => ({
    worker: null,
    showLogoutWarning: false,
    events: ["click", "keypress"],
    logoutCountdown: CONFIG.AUTO_LOGOUT_COUNTDOWN_SECS * 1000,
    // min to ms: 1 min * 60 sec/min * 1000 ms/sec. We subtract AUTO_LOGOUT_COUNTDOWN_SECS from the total auto logout timeout because we
    // want to show a warning window before actually logging out
    inactivityTimeout:
      CONFIG.AUTO_LOGOUT_MINS * 60 * 1000 - CONFIG.AUTO_LOGOUT_COUNTDOWN_SECS * 1000,
  }),

  methods: {
    ...mapActions(["AUTH_LOGOUT"]),

    async logOut() {
      await this.AUTH_LOGOUT();
      this.$router.push("/");
    },

    extendSession() {
      this.addEventListeners();
    },

    /**
     * Process incoming worker's messages
     * @param {Object} msg
     */
    async handleWorkerMessage(msg) {
      let data = JSON.parse(msg.data);
      // console.log(msg.data);
      if (data.title == "WARNING_TIMER_EXPIRED") {
        this.showLogoutWarning = true;
        this.removeEventListeners();
      } else if (data.title == "LOGOUT_COUNTDOWN_EXPIRED") {
        await this.logOut();
      } else if (data.title == "COUNTDOWN_VALUE") {
        this.logoutCountdown = data.countdown;
      }
    },

    addEventListeners() {
      this.events.forEach(event => {
        window.addEventListener(event, this.resetWarningTimer);
      });
    },

    removeEventListeners() {
      this.events.forEach(event => {
        window.removeEventListener(event, this.resetWarningTimer);
      });
    },

    resetWarningTimer() {
      this.showLogoutWarning = false;
      this.worker.postMessage(
        JSON.stringify({
          title: "RESET_WARNING_TIMER",
        })
      );
      this.logoutCountdown = CONFIG.AUTO_LOGOUT_COUNTDOWN_SECS * 1000;
    },
  },

  mounted() {
    this.worker = new Worker("/workers/autoLogoutWorker.js");
    this.worker.onmessage = async msg => {
      await this.handleWorkerMessage(msg);
    };
    this.addEventListeners();
    this.worker.postMessage(
      JSON.stringify({
        title: "INIT_TIMER",
        logoutCountdown: this.logoutCountdown,
        inactivityTimeout: this.inactivityTimeout,
      })
    );
  },

  /**
   * Removed web worker and all event listeners to avoid potentially unexpected behaviors
   */
  destroyed() {
    this.removeEventListeners();
    this.worker.terminate();
  },
};
</script>

<template>
  <Dialog
    modal
    :draggable="false"
    :visible="showLogoutWarning"
    :closable="false"
    :header="$t('AutoLogout.warningHeader')"
  >
    <p>
      {{ $t("AutoLogout.warningMessage") }}
      {{ logoutCountdown / 1000 }} {{ $t("AutoLogout.second")
      }}<template v-if="logoutCountdown > 1000">s</template>. {{ $t("AutoLogout.youCan") }}:
    </p>
    <template #footer>
      <Button :label="$t('AutoLogout.extendSession')" @click="extendSession"></Button>
      <Button :label="$t('Header.Signout')" @click="logOut"></Button>
    </template>
  </Dialog>
</template>
